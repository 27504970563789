import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import type { CalendarDay, CalendarDayTime } from '../graphql';

dayjs.extend(utc);
dayjs.extend(tz);

export function dayTimeToDate(dayTime: CalendarDayTime) {
	return new Date(dayTime.day.year, dayTime.day.month - 1, dayTime.day.day, dayTime.time.hour, dayTime.time.minute);
}

export function dayToDayJs(day: CalendarDay, timezone: string) {
	return dayjs.tz(`${day.year}-${day.month}-${day.day}`, timezone).startOf('day');
}

export function dateToDayTime(date: Date) {
	return {
		day: {
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			day: date.getDay(),
		},
		time: {
			hour: date.getHours(),
			minute: date.getMinutes(),
		},
	};
}

export const formatDateTime = new Intl.DateTimeFormat('en', {
	day: 'numeric',
	weekday: 'long',
	month: 'long',
	hour: 'numeric',
	minute: '2-digit',
}).format;

// Acceptable for <time datetime="…">
export const formatDateTimeAttribute = (date: Date, includeHours: boolean = true) => {
	let formatted = timeAttributeFormat(date).replace(/\//g, '-');
	if (!includeHours) {
		formatted = formatted.replace(/ .*$/, '');
	}
	return formatted;
};
const timeAttributeFormat = new Intl.DateTimeFormat('ja-JP', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: 'numeric',
	minute: '2-digit',
	hour12: false,
}).format;

const dayTimeFormatter = new Intl.DateTimeFormat('en', {
	hour: 'numeric',
	hour12: true,
});

const day = dayjs().startOf('day');
export const shortTimeframes: Record<string, [number, number]> = {
	'All day': [0, 24],
	'Early morning': [0, 10],
	'Late morning': [10, 12],
	'All morning': [0, 12],
	'Early afternoon': [12, 15],
	'Late afternoon': [15, 24],
	'All afternoon': [12, 24],
};

export const timeframes: Record<string, [number, number]> = {
	'All day': [0, 24],
	[`Early morning • before ${dayTimeFormatter.format(day.set('hour', 10).toDate())}`]: [0, 10],
	[`Late morning • ${dayTimeFormatter.formatRange(day.set('hour', 10).toDate(), day.set('hour', 12).toDate())}`]: [
		10, 12,
	],
	[`All morning • before ${dayTimeFormatter.format(day.set('hour', 12).toDate())}`]: [0, 12],
	[`Early afternoon • ${dayTimeFormatter.formatRange(day.set('hour', 12).toDate(), day.set('hour', 15).toDate())}`]: [
		12, 15,
	],
	[`Late afternoon • after ${dayTimeFormatter.format(day.set('hour', 15).toDate())}`]: [15, 24],
	[`All afternoon • after ${dayTimeFormatter.format(day.set('hour', 12).toDate())}`]: [12, 24],
};

export function timeFrameNameFromHours(start: number, end: number, short: boolean = false) {
	const found = Object.entries(short ? shortTimeframes : timeframes).find(([, [tStart, tEnd]]) => {
		return tStart === start && tEnd === end;
	});

	return found ? found[0] : undefined;
}
